<template>
    <div>
        <v-container fluid class="login-page" fill-height>
            <v-row no-gutters justify="center" align="center">
                <v-col cols="12" md="6">
                    <login-form 
                        @successfull-login="attemptingLogin"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue                  from 'vue';
import { mapGetters }       from 'vuex';
import LoginForm            from "../../components/Form/Auth/Login";
import PolygonBackground    from "../../components/Util/PolygonBackground";

export default {
    name: "LoginPage",
    components: {
        LoginForm,
        PolygonBackground
    },
    data() {
        return {
            animatePolygons: false
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn"
        })
    },
    methods: {
        attemptingLogin() {
            this.animatePolygons = true;
            setTimeout(() => {
                this.$router.push('/account');
            }, 2000);
        },
        goTo(routeName) {
            var searchObj = Vue.urlHelper.extractQuery();
            if (searchObj["company_id"]) {
                this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
            } else {
                this.$router.push({name: routeName});
            }
        }
    },
    created() {
        if (this.isLoggedIn) {
            this.goTo('Account');
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page {
    height: 80vh;
}
</style>
